import { useEffect, useState } from 'react';
import update from 'immutability-helper';
import history from '../../services/history';
import { getRestaurant } from '../../services/restaurantService';
import ReactPixel from 'react-facebook-pixel';
import TagManager from '@sooro-io/react-gtm-module';
import { getSubdomain } from '../../utils/domainUtils';
import { getDataLayerName } from '../../utils/gtmUtils';

export default function useCart() {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const [orderType, setOrderType] = useState();
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [changeFor, setChangeFor] = useState(0);
    const [neighborhood, setNeighborhood] = useState(null)
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [restaurant, setRestaurant] = useState({ "openIndoor": false, "openDeliveryTakeout": false });
    const [city, setCity] = useState(null)
    const [discountCoupon, setDiscountCoupon] = useState();
    const [discount, setDiscount] = useState(0);
    const [discountCode, setDiscountCode] = useState("");

    useEffect(() => {
        async function fetchRestaurant() {
            const domain = getSubdomain();

            const response = await getRestaurant(domain);

            const restaurant = response.data;
            const { fbPixelId, gtmId, orderTypes } = restaurant;

            if (fbPixelId) {
                ReactPixel.init(fbPixelId, {}, { debug: false, autoConfig: true });
                ReactPixel.trackSingle(fbPixelId, 'PageView');
            }

            if (gtmId) {
                const dataLayerName = getDataLayerName(gtmId);
                const tagManagerArgs = {
                    gtmId: gtmId,
                    dataLayerName: dataLayerName,
                }
                TagManager.initialize(tagManagerArgs);
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'page_view',
                    },
                    dataLayerName: dataLayerName,
                });
            }

            setRestaurant(restaurant);
            setOrderType(orderTypes[0]?.value)
            setLoading(false);
        }
        fetchRestaurant();
    }, [])

    function addToCart(product) {
        const { totalPrice, totalWithoutPromotionalPrice } = calculateTotalWithComplements(product);
        const newProduct = update(product, { $merge: { totalPrice: totalPrice, totalWithoutPromotionalPrice: totalWithoutPromotionalPrice } })
        const newTotal = total + (totalPrice * product.quantity);
        const newProducts = update(products, { $push: [newProduct] });
        setTotal(newTotal);
        setItemCount(newProducts.length);
        setProducts(newProducts);
        reapplyDiscountCoupon(newTotal);
    }

    function removeFromCart(indexes) {
        let newProducts = [...products];
        const orderedIndexes = indexes.sort(function (a, b) { return b - a });
        for (let i = 0; i < orderedIndexes.length; i++) {
            newProducts = update(newProducts, { $splice: [[orderedIndexes[i], 1]] });
        }
        if (newProducts.length === 0) {
            clearCart();
        } else {
            let newTotal = 0;
            newProducts.forEach(product => {
                const { totalPrice } = calculateTotalWithComplements(product);
                newTotal += totalPrice * product.quantity;
            });
            setTotal(newTotal);
            setItemCount(newProducts.length);
            setProducts(newProducts);
            reapplyDiscountCoupon(newTotal);
        }
    }

    function updateCart(index, product) {
        const { totalPrice, totalWithoutPromotionalPrice } = calculateTotalWithComplements(product);
        const newProduct = update(product, { $merge: { totalPrice: totalPrice, totalWithoutPromotionalPrice: totalWithoutPromotionalPrice } })
        const newProducts = update(products, { $splice: [[index, 1, newProduct]] });
        let newTotal = 0;
        newProducts.forEach(product => {
            const { totalPrice } = calculateTotalWithComplements(product);
            newTotal += totalPrice * product.quantity;
        });
        setTotal(newTotal);
        setItemCount(newProducts.length);
        setProducts(newProducts);
        reapplyDiscountCoupon(newTotal);
        history.push('/cart');
    }

    function clearCart() {
        setTotal(0);
        setItemCount(0);
        setProducts([]);
        setPaymentMethod(null);
        setChangeFor(0);
        setCpfCnpj("");
        setDiscountCoupon();
        setDiscount(0);
        setDiscountCode("");
    }

    function applyDiscountCoupon(discountCoupon) {
        let discountValue = discountCoupon.discount;
        if (discountCoupon.type.id === 1) {
            discountValue = total * (discountValue / 100);
        }
        setDiscountCoupon(discountCoupon);
        setDiscount(discountValue);
    }

    function removeDiscountCoupon() {
        setDiscountCoupon();
        setDiscount(0);
    }

    function reapplyDiscountCoupon(newTotal) {
        if (discountCoupon) {
            let discountValue = discountCoupon.discount;
            if (discountCoupon.type.id === 1) {
                discountValue = newTotal * (discountValue / 100);
            }
            setDiscount(discountValue);
        }
    }

    function calculateTotalWithComplements(product) {
        let totalPrice = product.promotionalUnitPrice
            ? product.promotionalUnitPrice
            : product.unitPrice;
        let totalWithoutPromotionalPrice = product.unitPrice;
        let totalComplements = 0;
        product.optionGroups.forEach(optionGroup => {
            const complements = optionGroup.complements ?? [];
            const selectedComplements = complements.filter(c => c.quantity > 0);
            const pizza = optionGroup.pizza;
            if (pizza) {
                var value = 0;
                const chargeForTheHighest = restaurant.chargeForTheHighest;
                if (chargeForTheHighest) {
                    const mostExpensive = selectedComplements.reduce(function (prev, current) {
                        return (prev.unitPrice > current.unitPrice) ? prev : current
                    }, 0)
                    value = mostExpensive === 0
                        ? 0
                        : mostExpensive.unitPrice;
                } else {
                    const selectedComplementsSize = selectedComplements.length;
                    if (selectedComplementsSize === 1) {
                        value = selectedComplements[0].unitPrice;
                    } else if (selectedComplementsSize > 1) {
                        selectedComplements.forEach(complement => {
                            value += complement.unitPrice / selectedComplementsSize;
                        });
                    }
                }

                totalComplements += value;
            } else {
                selectedComplements.forEach(complement => {
                    totalComplements += complement.quantity * complement.unitPrice;
                });
            }
        });
        totalPrice += totalComplements;
        totalWithoutPromotionalPrice += totalComplements;
        return { totalPrice, totalWithoutPromotionalPrice };
    }

    return {
        loading, addToCart, removeFromCart, clearCart, updateCart, total, itemCount, products, orderType,
        setOrderType, paymentMethod, setPaymentMethod, changeFor, setChangeFor, neighborhood, setNeighborhood, cpfCnpj, setCpfCnpj, restaurant,
        city, setCity, discountCoupon, applyDiscountCoupon, removeDiscountCoupon, discount, discountCode, setDiscountCode
    };
}
